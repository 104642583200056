import { Injectable } from '@angular/core';
import { CredentialsFacade, HttpService } from '@up/n-angular';
import * as moment from 'moment-timezone';
import {
  exhaustMap,
  filter,
  lastValueFrom,
  map,
  Observable,
  take,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  user$: Observable<any> = this.cF.selectedCredential$.pipe(
    map((credential) => credential?.payload)
  );
  member$ = this.user$.pipe(
    filter((user) => !!user?.member),
    map((user: any) => user?.member[0])
  );
  affiliate$ = this.user$.pipe(
    filter((user) => !!user?.affiliate),
    map((user: any) => user?.affiliate[0])
  );
  wallet$ = this.user$.pipe(
    filter((user) => !!user?.wallet),
    map((user: any) => user?.wallet[0])
  );
  subscriptions$ = this.user$.pipe(
    filter((user) => !!user?.subscriptions),
    map((user: any) => user?.subscriptions)
  );
  store$ = this.user$.pipe(
    filter((user) => !!user?.store),
    map((user: any) => user?.store[0])
  );
  isExpired$ = this.member$.pipe(
    filter((member) => !!member?.expiredAt),
    map((member: any) =>
      moment().tz('Asia/Hong_Kong').isAfter(moment(member.expiredAt))
    )
  );

  userInit$ = this.cF.selectedCredential$.pipe(
    filter((credential) => !!credential?.id),
    map((credential) => credential?.id as string),
    exhaustMap((id) =>
      this.http.find.byId({
        collection: 'users',
        id,
        queryParams: {
          lookups: [
            {
              from: 'members',
              localField: 'member',
              foreignField: '_id',
              as: 'member',
            },
            {
              from: 'affiliates',
              localField: 'affiliate',
              foreignField: '_id',
              as: 'affiliate',
            },
            {
              from: 'wallets',
              localField: 'member.wallet',
              foreignField: '_id',
              as: 'wallet',
            },
            {
              from: 'subscriptions',
              localField: 'member.subscriptions',
              foreignField: '_id',
              as: 'subscriptions',
            },
            {
              from: 'storesTemp',
              localField: 'store',
              foreignField: '_id',
              as: 'store',
            },
          ],
          project: {
            name: 1,
            gender: 1,
            username: 1,
            nickname: 1,
            email: 1,
            mobile: 1,
            roles: 1,
            member: 1,
            affiliate: 1,
            wallet: 1,
            subscriptions: 1,
            store: 1,
          },
        },
      })
    )
  );

  constructor(private cF: CredentialsFacade, private http: HttpService<any>) {}

  refresh(): void {
    lastValueFrom(
      this.userInit$.pipe(
        take(1),
        tap((payload) => this.cF.reloadSelectedCredentialPayload(payload))
      )
    );
  }
}
